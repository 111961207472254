<template>
	<v-form v-model="valid" :disabled="disabled">
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-text-field
						dense
						flat
						v-model="fields.attentionTo"
						label="attentionTo"
					></v-text-field>
				</v-col>
				<v-col cols="12">
					<v-text-field
						v-model="fields.streetNameAndNumber"
						dense
						flat
						label="streetNameAndNumber"
						required
					></v-text-field>
				</v-col>
				<v-col cols="4">
					<v-text-field
						dense
						flat
						v-model="fields.zipCode"
						label="zipCode"
						required
					></v-text-field>
				</v-col>
				<v-col cols="8">
					<v-text-field
						dense
						flat
						v-model="fields.city"
						label="city"
						required
					></v-text-field>
				</v-col>
				<v-col cols="12">
					<v-text-field
						dense
						flat
						v-model="fields.countryCode"
						label="countryCode"
						required
					></v-text-field>
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>
<script>
	import { mapGetters } from "vuex";
	export default {
		name: "AddressForm",
		props: {
			address: null,
			disabled: null,
		},
		data: () => ({
			valid: false,
			fields: {
				attentionTo: "",
				streetNameAndNumber: "",
				zipCode: "",
				city: "",
				countryCode: "",
			},
		}),
		computed: {
			...mapGetters({
				defaultAddress: "account/defaultAddress",
			}),
		},
		mounted() {
			if (this.address) {
				Object.assign(this.fields, this.address);
			}
		},
	};
</script>